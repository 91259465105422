import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  fontSize,
} from '../utilities';

const StyledPostMeta = styled.section`
  position: sticky;
  top: 20px;
  padding: 30px;
  color: ${standardColours.white};
  background-color: ${brandColours.primary};

  ${minBreakpointQuery.small`
    padding: 40px;
  `}

  ${minBreakpointQuery.large`
    padding: 50px;
  `}

  ${minBreakpointQuery.xxlarge`
    padding: 60px;
  `}
`;

const StyledPostMetaBlock = styled.div`
  margin: 20px 0;

  ${minBreakpointQuery.small`
    margin-top: 25px;
    margin-top: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
    margin-top: 30px;
  `}

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledPostMetaHeading = styled.h2`
  ${fontSize(18)};

  ${minBreakpointQuery.tiny`
    ${fontSize(20)};
  `}
`;

const StyledPostMetaText = styled.p`
  margin-top: 12px;
  line-height: 1.7;

  ${minBreakpointQuery.tiny`
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.small`
    margin-top: 14px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 16px;
  `}

  a {
    text-decoration: underline;
  }
`;

const PostMeta = ({ date, location }) => {
  return (
    <StyledPostMeta>
      <StyledPostMetaBlock>
        <StyledPostMetaHeading>Date</StyledPostMetaHeading>
        <StyledPostMetaText>
          <time dateTime={date.attribute}>{date.text}</time>
        </StyledPostMetaText>
      </StyledPostMetaBlock>
      <StyledPostMetaBlock>
        <StyledPostMetaHeading>Location</StyledPostMetaHeading>
        <StyledPostMetaText>
          {location}
          <br />
          <a
            href={`https://www.google.com/maps/dir//${location}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Get Directions
          </a>
        </StyledPostMetaText>
      </StyledPostMetaBlock>
    </StyledPostMeta>
  );
};

export default PostMeta;
